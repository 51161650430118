import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNOUT,
	SIGNUP,
	SIGNIN_WITH_GOOGLE,
	SIGNIN_WITH_FACEBOOK,
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
	signUpSuccess,
	signInWithGoogleAuthenticated,
	signInWithFacebookAuthenticated,
} from '../actions/Auth';

import FirebaseService from 'services/FirebaseService';
import axiosHR from 'auth/axiosHR';

const getLogin = async (username, password) => {
	return await axiosHR().post('login', {
		username: username,
		password: password,
	});
};

export function* signInWithFBEmail() {
	yield takeEvery(SIGNIN, function* ({ payload }) {
		const { email, password } = payload;
		try {
			const res = yield getLogin(email, password);
			if (res.data.response.code === 200) {
				// console.log('MSG:', res.data.data);
				console.log('MSG23:', res.data);

				localStorage.setItem(AUTH_TOKEN, res.data.data.token);
				localStorage.setItem('user_role', res.data.data.emp_role);
				localStorage.setItem('USER', res.data.data.user);
				localStorage.setItem('EMP_NAME', res.data.data.emp_name);
				localStorage.setItem('PAGES', JSON.stringify(res.data.data.pages));
				localStorage.setItem('REPORTS', JSON.stringify(res.data.data.reports));
				yield put(authenticated(res.data.data.user));
				yield put(authenticated(res.data.data.user));
			} else {
				yield put(showAuthMessage(res.data.response.msg));
			}
		} catch (err) {
			yield put(showAuthMessage(err.response.data.response.msg));
		}
	});
}

export function* signOut() {
	yield takeEvery(SIGNOUT, function* () {
		try {
			const signOutUser = yield call(FirebaseService.signOutRequest);
			if (signOutUser === undefined) {
				localStorage.removeItem(AUTH_TOKEN);
				localStorage.removeItem('PAGES');
				localStorage.removeItem('REPORTS');
				yield put(signOutSuccess(signOutUser));
			} else {
				yield put(showAuthMessage(signOutUser.message));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signUpWithFBEmail() {
	yield takeEvery(SIGNUP, function* ({ payload }) {
		const { email, password } = payload;
		try {
			const user = yield call(
				FirebaseService.signUpEmailRequest,
				email,
				password
			);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signUpSuccess(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* signInWithFBGoogle() {
	yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
		try {
			const user = yield call(FirebaseService.signInGoogleRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithGoogleAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* signInWithFacebook() {
	yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
		try {
			const user = yield call(FirebaseService.signInFacebookRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithFacebookAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(signInWithFBEmail),
		fork(signOut),
		fork(signUpWithFBEmail),
		fork(signInWithFBGoogle),
		fork(signInWithFacebook),
	]);
}
