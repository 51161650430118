import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

export const AppViews = () => {
	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route
					path={`${APP_PREFIX_PATH}/dashboards`}
					component={lazy(() => import(`./dashboards`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/apps`}
					component={lazy(() => import(`./apps`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/organisation`}
					component={lazy(() => import(`./organisation`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/settings`}
					component={lazy(() => import(`./settings`))}
				/>
				<Redirect
					from={`${APP_PREFIX_PATH}`}
					to={`${APP_PREFIX_PATH}/dashboards`}
				/>
			</Switch>
		</Suspense>
	);
};

export default React.memo(AppViews);
