import Axios from 'axios';

const axiosHR = () => {
	// const baseURL = 'http://192.168.100.7:5000/api/v1';
	const baseURL =
		process.env.NODE_ENV === 'development'
			? 'https://gfs-hrms.backslashinc.com/api/'
			: 'https://gfs-hrms.backslashinc.com/api/';

	let headers = {};

	if (localStorage.auth_token) {
		headers.Authorization = `Token ${localStorage.auth_token}`;
	}

	// console.log('HEADERS', headers);

	const axiosBC = Axios.create({
		baseURL: baseURL,
		headers,
	});

	return axiosBC;
};

export default axiosHR;
