import {
  DashboardOutlined,
  AppstoreOutlined,
  SafetyOutlined,
  DotChartOutlined,
  ClockCircleOutlined,
  FileDoneOutlined,
  CalendarOutlined,
  PlusCircleOutlined,
  FundOutlined,
  SolutionOutlined,
  ApartmentOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";

const extraNavTree = [
  {
    key: "extra",
    path: `${APP_PREFIX_PATH}/pages`,
    title: "sidenav.pages",
    icon: PlusCircleOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "extra-auth",
        path: `${AUTH_PREFIX_PATH}`,
        title: "sidenav.authentication",
        icon: SafetyOutlined,
        breadcrumb: true,
        submenu: [
          // {
          // 	key: 'extra-auth-login-1',
          // 	path: `${AUTH_PREFIX_PATH}/login-1`,
          // 	title: 'sidenav.authentication.login.1',
          // 	icon: '',
          // 	breadcrumb: true,
          // 	submenu: [],
          // },
          // {
          // 	key: 'extra-auth-login-2',
          // 	path: `${AUTH_PREFIX_PATH}/login-2`,
          // 	title: 'sidenav.authentication.login.2',
          // 	icon: '',
          // 	breadcrumb: true,
          // 	submenu: [],
          // },
          // {
          // 	key: 'extra-auth-register-1',
          // 	path: `${AUTH_PREFIX_PATH}/register-1`,
          // 	title: 'sidenav.authentication.register.1',
          // 	icon: '',
          // 	breadcrumb: true,
          // 	submenu: [],
          // },
          // {
          // 	key: 'extra-auth-register-2',
          // 	path: `${AUTH_PREFIX_PATH}/register-2`,
          // 	title: 'sidenav.authentication.register.2',
          // 	icon: '',
          // 	breadcrumb: true,
          // 	submenu: [],
          // },
          {
            key: "extra-auth-forgot-password",
            path: `${AUTH_PREFIX_PATH}/forgot-password`,
            title: "sidenav.authentication.forgetPassword",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      // {
      // 	key: 'extra-errors',
      // 	path: `${AUTH_PREFIX_PATH}/error-1`,
      // 	title: 'sidenav.errors',
      // 	icon: StopOutlined,
      // 	breadcrumb: true,
      // 	submenu: [
      // 		{
      // 			key: 'extra-errors-error-1',
      // 			path: `${AUTH_PREFIX_PATH}/error-1`,
      // 			title: 'sidenav.errors.error.1',
      // 			icon: '',
      // 			breadcrumb: true,
      // 			submenu: [],
      // 		},
      // 		{
      // 			key: 'extra-errors-error-2',
      // 			path: `${AUTH_PREFIX_PATH}/error-2`,
      // 			title: 'sidenav.errors.error.2',
      // 			icon: '',
      // 			breadcrumb: true,
      // 			submenu: [],
      // 		},
      // 	],
      // },
    ],
  },
];

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "sidenav.dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboards-home",
        path: `${APP_PREFIX_PATH}/dashboards/home`,
        title: "sidenav.dashboard.home",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-profile",
        path: `${APP_PREFIX_PATH}/dashboards/profile`,
        title: "sidenav.dashboard.profile",
        icon: DotChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-payslip",
        path: `${APP_PREFIX_PATH}/dashboards/payslip`,
        title: "sidenav.dashboard.payslip",
        icon: FileDoneOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const organisationNavTree = [
  {
    key: "organisation",
    path: `${APP_PREFIX_PATH}/organisation`,
    title: "sidenav.organisation",
    icon: ApartmentOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "department-organisation",
        path: `${APP_PREFIX_PATH}/organisation/department`,
        title: "sidenav.organisation.department",
        icon: ApartmentOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "designation-organisation",
        path: `${APP_PREFIX_PATH}/organisation/designation`,
        title: "sidenav.organisation.designation",
        icon: ApartmentOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "employee-organisation",
        path: `${APP_PREFIX_PATH}/organisation/employee`,
        title: "sidenav.organisation.employee",
        icon: ApartmentOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "shift-organisation",
        path: `${APP_PREFIX_PATH}/organisation/shift`,
        title: "sidenav.organisation.shift",
        icon: ApartmentOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "shift-schedule",
        path: `${APP_PREFIX_PATH}/organisation/shift-schedule`,
        title: "sidenav.organisation.shift-schedule",
        icon: ApartmentOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "org-holiday",
        path: `${APP_PREFIX_PATH}/organisation/holiday`,
        title: "sidenav.organisation.holiday",
        icon: ApartmentOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "loan-payment-organisation",
        path: `${APP_PREFIX_PATH}/organisation/loan-payment`,
        title: "sidenav.organisation.loan-payment",
        icon: ApartmentOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "salary-check",
        path: `${APP_PREFIX_PATH}/organisation/salary-check`,
        title: "sidenav.organisation.salary-check",
        icon: FundOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "salary-run",
        path: `${APP_PREFIX_PATH}/organisation/salary-run`,
        title: "sidenav.organisation.salary-run",
        icon: FundOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "project-organisation",
        path: `${APP_PREFIX_PATH}/organisation/project`,
        title: "sidenav.organisation.project",
        icon: FundOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const appsNavTree = [
  {
    key: "config",
    path: `${APP_PREFIX_PATH}/apps`,
    title: "sidenav.apps",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "leave",
        path: `${APP_PREFIX_PATH}/apps/leave`,
        title: "sidenav.apps.leave",
        icon: SolutionOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "attendance",
        path: `${APP_PREFIX_PATH}/apps/attendance`,
        title: "sidenav.apps.attendance",
        icon: ClockCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: "apps-calendar",
        path: `${APP_PREFIX_PATH}/apps/calendar`,
        title: "sidenav.apps.calendar",
        icon: CalendarOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "apps-approval",
        path: `${APP_PREFIX_PATH}/apps/approval`,
        title: "sidenav.apps.approval",
        icon: SolutionOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "apps-requests",
        path: `${APP_PREFIX_PATH}/apps/requests`,
        title: "sidenav.apps.requests",
        icon: SolutionOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "apps-loan",
        path: `${APP_PREFIX_PATH}/apps/loan`,
        title: "sidenav.apps.loan",
        icon: SolutionOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      // 	key: 'apps-project',
      // 	path: `${APP_PREFIX_PATH}/apps/project`,
      // 	title: 'sidenav.apps.project',
      // 	icon: CalendarOutlined,
      // 	breadcrumb: true,
      // 	submenu: [],
      // },
      // {
      // 	key: 'apps-loan',
      // 	path: `${APP_PREFIX_PATH}/apps/loan`,
      // 	title: 'sidenav.apps.loan',
      // 	icon: CalendarOutlined,
      // 	breadcrumb: true,
      // 	submenu: [],
      // },

      // {
      // 	key: 'apps-project',
      // 	path: `${APP_PREFIX_PATH}/apps/project`,
      // 	title: 'sidenav.apps.project',
      // 	icon: BulbOutlined,
      // 	breadcrumb: true,
      // 	submenu: [
      // 		{
      // 			key: 'apps-project-list',
      // 			path: `${APP_PREFIX_PATH}/apps/project/list`,
      // 			title: 'sidenav.apps.project.list',
      // 			icon: '',
      // 			breadcrumb: false,
      // 			submenu: [],
      // 		},
      // 		{
      // 			key: 'apps-project-scrumboard',
      // 			path: `${APP_PREFIX_PATH}/apps/project/scrumboard`,
      // 			title: 'sidenav.apps.project.scrumboard',
      // 			icon: '',
      // 			breadcrumb: false,
      // 			submenu: [],
      // 		},
      // 	],
      // },
    ],
  },
];

const navigationConfig = [
  ...dashBoardNavTree,
  ...appsNavTree,
  ...organisationNavTree,
  ...extraNavTree,
];

export default navigationConfig;
